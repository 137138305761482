<template>
  <Layout>
    <div ref="w" class="page-home scrollable-container no-scrollbar">

      <div v-if="userStore.loggedIn" class="panel-create-post">
        <ProfilePostBuilder :scheduled="scheduled" @post-created="handleFeedUpdate" />
      </div>

      <div class="mb-3" v-if="userStore.approvedCreator && !profileComplete">
        <ProfileProgressBar />
      </div>

      <div v-if="feedPosts" class="feed">
        <Post v-for="post in feedPosts" :key="post.id" :post="post" :preview="true"
          @post-purchased="handlePostPurchased" @post-liked="handlePostLiked" @post-deleted="handlePostDeleted" />

        <div v-element-visibility="loadMorePosts" class="the-end">
          <Logo :loading="pending" variant="square" size="h-14" />
        </div>
      </div>
    </div>

    <template #sidebar>
      <SuggestionsPanel />
      <!--
        <Panel label="Friends" :collapsible="true">
          <div class="w-full flex flex-row items-center justify-center gap-2">
            <FriendCard />
            <FriendCard />
          </div>
        </Panel>
        -->
      <SiteLegalLinks />
    </template>
  </Layout>
</template>

<script setup>
/******************************************************
 *   	Imports
 *****************************************************/
import { useUserStore } from "@/store/user"
import { vElementVisibility } from '@vueuse/components'

/******************************************************
 *   	Setup Define
 *****************************************************/
definePageMeta({
  name: "home",
  skipAuth: true,
})

useHead({
  title: "favorite.ly",
  meta: [
    {
      name: "description",
      content: "Come say hi to me over at favorite.ly",
    },
    { name: "og:site_name", content: "Favorite.ly" },
    { name: "og:type", content: "website" },
    { name: "og:title", content: "Favorite.ly" },
    {
      name: "og:description",
      content: "Come say hi to me over at favorite.ly",
    },
    {
      name: "og:url",
      content: "https://favorite.ly/",
    },
    {
      name: "og:image",
      content:
        "https://favoritely-assets.s3.us-west-1.amazonaws.com/production/assets/photo_placeholders/1200x630.png",
    },
    { name: "twitter:site", content: "@realfavoritely" },
    { name: "twitter:title", content: "Favorite.ly" },
    { name: "twitter:card", content: "summary_large_image" },
    {
      name: "twitter:url",
      content: "https://favorite.ly/",
    },
    {
      name: "twitter:description",
      content: "Come say hi to me over at favorite.ly",
    },
    {
      name: "twitter:image",
      content:
        "https://favoritely-assets.s3.us-west-1.amazonaws.com/production/assets/photo_placeholders/1200x630.png",
    },
  ],
})

/******************************************************
 *   	Composables, Refs & Variables
 *****************************************************/
const config = useRuntimeConfig()
const userStore = useUserStore()
const route = useRoute()
const dayjs = useDayjs()

const lastPage = ref(1)
const pageNumber = ref(1)
const feedPosts = ref([])
const scheduled = ref(null)
const {
  totalProfileCompletionSteps,
  currentProfileCompletionStep,
  profileComplete
} = useProfileCompletion()

/******************************************************
 *   	API Calls
 *****************************************************/
const { pending, data, refresh } = await useAuthFetch(
  config.public.API_URL + "/api/posts?page=1",
)

if (data.value) {
  lastPage.value = data.value.pagination.totalPages
  feedPosts.value = data.value.items
}

/******************************************************
 *   	Computed / Functions
 *****************************************************/
const thisIsTheEnd = computed(() => pageNumber.value === lastPage.value)

const updateFeed = async () => {
  await refresh()
  if (data.value) {
    lastPage.value = data.value.pagination.totalPages
    feedPosts.value = data.value.items
  }
}

const highlightPost = (postId) => {
  const ele = document.getElementById(postId)
  ele.classList.add('animate-twice')
  ele.classList.add("animate-pulse")
}

/******************************************************
 *   	Event Handlers
 *****************************************************/
const loadMorePosts = async () => {
  if (pending.value) return
  if (thisIsTheEnd.value) return

  pending.value = true
  pageNumber.value++

  const newPosts = await $api(config.public.API_URL + "/api/posts", {
    key: `newPosts-${pageNumber.value}`,
    params: { page: pageNumber.value },
  })

  feedPosts.value = [...feedPosts.value, ...newPosts.items]
  pending.value = false
}

const handleFeedUpdate = async (post) => {
  await updateFeed()
}

const handlePostPurchased = async (updatedPost) => {
  const oldPost = feedPosts.value.find((post) => post.id === updatedPost.id)
  const index = feedPosts.value.indexOf(oldPost)
  feedPosts.value[index] = updatedPost
  useShootConfetti()
  highlightPost(updatedPost.id)
}

const handlePostLiked = async (postId, value) => {
  const target = feedPosts.value.find((post) => post.id === postId)
  target.likedByUser = value
}

const handlePostDeleted = (value) => {
  feedPosts.value = feedPosts.value.filter((post) => post.id !== value)
}

/******************************************************
 *   	Watchers
 *****************************************************/
if (route.query.scheduled) {
  scheduled.value = dayjs(parseInt(route.query.scheduled)).toDate()
}
</script>

<style lang="scss" scoped>
.page-home {
  @apply w-full;
}

.scrollable-container {
  @apply flex flex-col flex-1 h-full grow overflow-y-auto px-0;
}

.panel-create-post {
  @apply w-full mb-3;
  @apply hidden md:block;
}

.feed {
  @apply w-full;
  @apply flex flex-col gap-3;
}

.the-end {
  @apply w-full flex justify-center items-center p-2 mt-8 mb-4;
}
</style>
